/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import '@fontsource/raleway/300.css'
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/700.css';
// import '@fontsource/lato/300.css'
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import './src/css/main.scss';

const injectIubendaScript = () => {
  const iubendaScriptId = 'iubenda-script-in-gatsby';
  // Remove existing iubenda script and reinsert it to trigger re-execution
  const existingScript = document.getElementById(iubendaScriptId);
  if (existingScript) {
    existingScript.parentNode.removeChild(existingScript);
  }
  const s = document.createElement('script');
  s.id = iubendaScriptId;
  s.type = 'text/javascript';
  s.src = 'https://cdn.iubenda.com/iubenda.js';
  s.async = true;
  document.getElementsByTagName('head')[0].appendChild(s);
};

const injectMiodottoreScript = () => {
  const miodottoreScriptId = 'zl-widget-s';
  // Remove existing iubenda script and reinsert it to trigger re-execution
  const existingScript = document.getElementById(miodottoreScriptId);
  if (existingScript) {
    existingScript.parentNode.removeChild(existingScript);
  }
  const s = document.createElement('script');
  s.id = miodottoreScriptId;
  s.type = 'text/javascript';
  s.src = '//platform.docplanner.com/js/widget.js';
  s.async = true;
  document.getElementsByTagName('head')[0].appendChild(s);
};

export const onRouteUpdate = () => {
  const injectExternaScripts = () => {
    injectIubendaScript();
    injectMiodottoreScript();
  };
  // Check wheter the document has loaded or not
  if (document.readyState === 'complete') {
    // console.log('Immediately insert external scripts');
    injectExternaScripts();
  } else {
    // console.log('Insert external scripts on load event');
    if (window.addEventListener) {
      window.addEventListener('load', injectExternaScripts, false);
    } else if (window.attachEvent) {
      window.attachEvent('onload', injectExternaScripts);
    } else {
      window.onload = injectExternaScripts;
    }
  }
};
